.page {
  &__brands {

  }
  &__popular-brands{
    position: relative;
  }
}

.brands-page {
  &__container-hf {
  }

  &__grid {
    margin: rem(30) 0;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: rem(10);
    @media(max-width:$pc){
      grid-template-columns: repeat(5, 1fr);
    }
    @media(max-width:$tablet){
      grid-template-columns: repeat(4, 1fr);
    }
    @media(max-width:$mobile){
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .brands-item__image{
    padding-bottom: percent(100,177);
  }
  .brands-item{
    &__image {
      &:before{
        display: none;
      }
    }
  }
}
.popular-brands{
  margin-top: rem(20);
  &__show-all{
    @media(max-width:$tablet){

    position: absolute;
    top: rem(5);
    right: rem(10);
    
    p{
      display: none;
    }
    &:before{
      content: "все";

    }

    padding: rem(6.5) rem(15);
    border: 1px solid #DADADA;
    border-radius: 1000px;
    background: none;

    }
  }
}

