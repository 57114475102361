.popup-add-review {
	.rating-item-fill, .rating-item:before, .rating-item-fill:before{
		font-size: rem(24);
	}
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	height: calc(100vh);
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(10, 10, 10, 0.5);
	z-index: 20000;
	padding: rem(10);
	overflow: scroll;
	&__wrapper {
		@media(max-width:$tablet){
			max-height: 60%;
		}
		max-height: 100%;
		overflow: scroll;
		max-width: rem(700);
	}

	&__content {
		@include adaptiveValue("padding", 30, 10);
		background-color: #F1F0ED;
		position: relative;
	}
	&__close{
		position: absolute;
		right: rem(20);
		top: rem(20);
		@media(any-hover: hover){
		  &:hover{
			  cursor: pointer;
		  }
		}
	}
	&__header {
		font-family: 'Lovelace';
		color: #363636;
		margin-bottom: rem(20);
	}

	&__header-title {
		font-weight: 400;
		font-size: rem(30);
		@include adaptiveValue("font-size", 30, 20);
		line-height: math.div(42,30);
		margin-bottom: rem(8);
	}

	&__header-subtitle {
		font-weight: 400;
		font-size: rem(22);
		@include adaptiveValue("font-size", 22, 16);
		line-height: math.div(31,22);
	}

	&__form {
		display: flex;
		flex-direction: column;
		gap: rem(20);
	}
}
.add-review-form {
	textarea{
		width: 100%;
	}
	&__input-box {
		display: flex;
		flex-direction: column;
		gap: rem(8);
	}

	&__label {
		font-family: 'Lovelace';
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		color: #1B1B1E;
	}

	&__input {
		background-color: #FFFFFF;
		border: 1px solid #E5E7EB;
		border-radius: 8px;
		padding: rem(12) rem(16);
		font-size: rem(16);
	}

	&__input-file-box {
		display: flex;
		gap: rem(10);
	}

	&__input-file-label {
		background-color: rgba(148, 160, 115, 0.2);
		border: 1px solid #94A073;
		border-radius: 8px;
		display: inline-flex;
		align-items: center;
		flex-direction: column;
		padding: rem(45) rem(30);
		align-self: flex-start;
		text-align: center;
		@media(max-width:$tablet){
			padding: rem(15) rem(10);
		}
		@media(any-hover: hover){
		  &:hover{
			  cursor: pointer;
		  }
		}
	}
	&__input-file-input {
		position: absolute;
		opacity: 0;
		width: 0;
		height: 0;
	}
}
.add-review-form {
	&__input-uploaded-photos {
		display: flex;
		gap: rem(10);
		width: fit-content;
		flex-wrap: wrap;
	}

	&__input-uploaded-photo {
		position: relative;
		padding-bottom: rem(140);
		flex: 0 0 rem(140);
		width: 140px;
		span{
			position: absolute;
			z-index: 10;
			right: 0px;
			svg{
				pointer-events: none;
			}
			path{
				fill: #fff;
			}
			@media(any-hover: hover){
			  &:hover{
			        cursor: pointer;
			  }
			}
		}
		&:before{
		    content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: rgba(10, 10, 10, 0.5);
			z-index: 2;
		}
		img {
		    position: absolute;
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;
		    object-fit: cover;
		}
	}
}
.popup-mark{
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100vw;
	height: calc(100vh);
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(10, 10, 10, 0.5);
	z-index: 20000;
	@media(min-width:$tablet) {
		padding: rem(10);
	}
	overflow-y: auto;
	&__wrapper {
		max-height: 100%;
		overflow-y: auto;
		width: 100%;
		@media(min-width:$tablet){
			max-width: rem(1200);
			min-height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	&__content {
		width: 100%;
		background-color: #F1F0ED;
		position: relative;
	}
	&__close{
		position: absolute;
		right: rem(20);
		top: rem(20);
		@media(any-hover: hover){
			&:hover{
				cursor: pointer;
			}
		}
	}
	&__header {
		font-family: 'Lovelace';
		color: #363636;
		padding: rem(20) 0;
		background-color: #E8EBE2;
		display: flex;
		align-items: center;
		justify-content: center;

	}
	&__image{
		position: relative;
		padding-bottom: rem(160);
		flex: 0 0 rem(244);
		img {
		    position: absolute;
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;
		    object-fit: contain;
		}
	}
	&__text{
		padding: rem(40) rem(50);
		p{
			font-family: 'Gilroy';
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 25px;
			color: #5C5C5C;
		}
	}
}
.modal{
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0,0,0,0.4);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100001;
	padding: rem(10);
	&__content{
		@media(max-width:$tablet){
			padding: rem(15);
		}
		border-radius: 5px;
		width: 100%;
		max-width: rem(800);
		flex: 1 1 auto;
		position: relative;
		max-height: 100%;
		overflow-y: scroll;
		overflow-x: hidden;
		background-color: #fff;
		padding: rem(20);
	}
	&__close{
		width: rem(25);
		height: rem(25);
		position: absolute;
		top: rem(10);
		right: rem(25);
		@media(max-width:$tablet){
			top: rem(15);
			right: rem(25);
			width: rem(30);
			height: rem(30);
		}
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		z-index: 1;
		transform: translateY(25%);
		border: 1px solid #8C9677;
		border-radius: 50%;
		span{
			display: block;
			width: rem(18);
			flex: 0 0 rem(2);
			border-radius: rem(1);
			background-color: #8C9677;
			transform-origin:  center;
		}
		span:first-child{
			transform: rotate(45deg);
		}
		span:last-child{
			transform: rotate(-45deg);
			margin-top: -2px;
		}
	}
}
.modal-enter {
	opacity: 0;
}
.modal-enter-active {
	opacity: 1;
	transition: opacity 300ms;
}
.modal-exit {
	opacity: 1;
}
.modal-exit-active {
	opacity: 0;
	transition: opacity 300ms;
}
.modal-purchase {
	&__header {
		padding: rem(17) 0;
		background-color: #E8EBE2;
	}

	&__icon {
		position: relative;
		@include adaptiveValue("padding-bottom", 176, 74);
		img {
		    position: absolute;
		    top: 0;
		    left: 0;
		    width: 100%;
		    height: 100%;
		    object-fit: contain;
		}
	}

	&__text {
		background-color: #F1F0ED;
		padding-top: rem(59);
		padding-bottom: rem(64);
		text-align: center;
		@media(max-width:$tablet){
			padding: rem(23);
		}
	}

	&__title {
		font-weight: 700;
		@include adaptiveValue("font-size", 50, 24);
		line-height: math.div(50,50);
		text-align: center;
		color: #5C5C5C;
		@include adaptiveValue("margin-bottom", 47, 14);
	}

	&__subtitle {
		display: inline-block;
		font-weight: 400;
		font-size: rem(16);
		line-height: math.div(25,16);
		color: #5C5C5C;
		p{
			text-align: center;
		}
	}
}
.modal-alert {
	&__header {
		
	}

	&__title {
		padding-bottom: rem(20);
	}

	&__description {
		margin-bottom: rem(20);
		font-weight: 300;
		font-size: rem(20);
		line-height: math.div(28,30);
	}

	&__actions {
		display: flex;
		align-items: center;
		column-gap: rem(8);
	}

	&__button {
		background-color: rgba(148, 160, 115, 0.2);
		border-radius: 10px;
		padding: rem(14) rem(30);
		font-weight: 300;
		font-size: rem(14);
		&_yes{
			background-color: #94A073;
			color: #fff;
		}
	}
}
