.page {
  &__auth {
  }
}

.auth {
  &__wrapper{
    display: flex;
    height: calc(100vh - 150px);
    min-height: rem(961);
    @media(max-width:$tablet){
        height: auto;
    }
  }
  &__image {
    flex: 0 0 50%;
    position: relative;
    height: 100%;
    background: linear-gradient(180deg, #94A073 0%, #6E7B4B 100%);
    @media(max-width:$tablet){
      display: none;
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
  }
  &__form-box {
    flex: 0 0 50%;
    @media(max-width:$tablet){
        flex: 1 1 auto;
      justify-content: flex-start;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 rem(30);

  }
  &__form {
    max-width: rem(600);
    width: 100%;
    @media(max-width:$tablet){
      margin-top: rem(30);
    }
  }
}

.auth-form {
  &__title {
    font-family: 'Lovelace';
    font-style: normal;
    font-weight: 400;
    font-size: rem(30);
    line-height: math.div(42,30);
    color: #363636;
    margin-bottom: rem(15);
  }

  &__subtitle {
    font-family: 'Gilroy';
    font-weight: 400;
    font-size: rem(16);
    line-height: math.div(19,16);
    color: #989898;
    margin-bottom: rem(15);
  }

  &__inputs {
    font-family: 'Gilroy';
    font-weight: 400;
    font-size: rem(16);
    line-height: math.div(19,16);
    color: #989898;
    display: flex;
    flex-direction: column;
    gap: rem(15);
    margin-bottom: rem(15);
  }

  &__input-box {
    display: flex;
    flex-direction: column;
    gap: rem(5);
    position: relative;
  }

  &__input-label {
  }

  &__input {
    padding: rem(20.5) rem(10);
    outline: none;
    &:focus{
      border: 1px solid #94A073;
    }
    &.error{
      border: 1px solid #9a2c2c;
    }
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
  }

  &__submit {
    display: block;
    width: 100%;
    text-align: center;
    padding: rem(20) 0;
    background: rgba(148, 160, 115, 0.2);
    border: 1px solid #94A073;
    border-radius: 5px;
    color: #41492C;
    margin-bottom: rem(15);

  }

  &__link {
    color: #7C7C7C;
    &:not(:last-child){
      margin-right: rem(10);
    }
  }
}
