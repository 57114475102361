.swiper {
	overflow: hidden;
}
.swiper-wrapper {
	width: 100%;
	height: 100%;
	box-sizing: content-box;
	display: flex;
	position: relative;
}
.swiper-vertical {
	.swiper-wrapper {
		flex-direction: column;
	}
}
.swiper-autoheight {
	.swiper-wrapper {
		align-items: flex-start;
	}
}
.swiper-initialized {
	.swiper-slide {
		flex-shrink: 0;
		// transition-property: transform;
	}
}
.swiper-android {
	.swiper-slide,
	.swiper-wrapper {
		transform: translate3d(0px, 0, 0);
	}
}
.swiper-button-lock {
	display: none !important;
}
.swiper-grid > .swiper-wrapper {
	flex-wrap:wrap;
}
.swiper-grid-column > .swiper-wrapper {
	flex-wrap:wrap;
	flex-direction:column;
}
