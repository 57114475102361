/*! autoprefixer grid: autoplace */

@use "sass:math";

// Подключение миксинов ===========================================================================================================================================================================================================================================================================
@import "src/scss/base/mixins";

// Подключение шрифтов ===========================================================================================================================================================================================================================================================================
//&display=swap - добавить при подключении через плагин
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&display=swap");
// Подключить если есть локальные файлы шрифтов
@import "src/scss/fonts/fonts";

// Подключение иконочных шрифтов ==============================================================================================================================================================================================================================================================================
// Подключить если есть файл иконочного шрифта
@import "fonts/icons";

// Шрифт по умолчанию ==============================================================================================================================================================================================================================================================================
$fontFamily: "Gilroy";
$fontSize: rem(14); // где 14(px) - размер шрифта по умолчанию из макета

// Основные цвета
$mainColor: #5C6477; // Цвет шрифта по умолчанию

// НАСТРОЙКИ ===============================================================================================================================================================================================================================================================================================
// Минимальная ширина страницы
$minWidth: 320;
// Ширина полотна (макета)
$maxWidth: 1920;
// Ширина ограничивающего контейнера (0 = нет ограничения)
$maxWidthContainer: 1860;
// Общий отступ у контейнера
// (30 = по 15px слева и справа, 0 = нет отступа)
$containerPadding: 20;

// Ширина срабатывания первого брейкпоинта
$containerWidth: $maxWidthContainer + $containerPadding;

// Брейк-поинты
$pc: em($containerWidth); // ПК, ноутбуки, некоторые планшеты в горизонтальном положении
$tablet: em(991.98); // Планшеты, некоторые телефоны в горизонтальном положении
$mobile: em(767.98); // Телефоны L
$mobileSmall: em(479.98); // Телефоны S

// Тип адаптива:
// 1 = отзывчивость (у контейнера нет брейкпоинтов),
// 2 = по брейк-поинтам (контейнер меняет свою ширину по брейк-поинтам)
$responsiveType: 1;

// Обнуление и общие параметры ============================================================================================================================================================================================== =============================================================================================== ===============================================================================================
@import "src/scss/base/null";
p{
  white-space: pre-wrap;
}
a {
  @media (any-hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
}
body {
  // Скролл заблокирован
   &.lock, .lock & {
    overflow: hidden !important;
    touch-action: none !important;
  }
  .menu-open & {
    overflow: hidden;
    touch-action: none;
  }
  // Сайт загружен
  .loaded & {
  }
}
#__next{
  height: 100%;
}
// Оболочка ============================================================================================================================================================================================================================================================================================================================================================================================================================================
.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  // Прижимаем footer
  > main {
    flex: 1 1 auto;
  }

  // Фикс для слайдеров
  > * {
    min-width: 0;
  }

  position: relative;
  background-color: #F1F0ED;


}

// Ограничивающий контейнер ======================================================================================================================================================================================================================

/*
(i) Стили будут применяться ко 
всем классам содержащим *__container
Например header__container, main__container и т.д.
Снипет (HTML): cnt
*/
@if ($responsiveType==1) {
  // Отзывчивая
  [class*="__container"] {
    @if ($maxWidthContainer>0) {
      max-width: rem($maxWidthContainer);
      box-sizing: content-box;
      margin: 0 auto;
    }
    @if ($containerPadding>0) {
      padding: 0 rem(math.div($containerPadding, 2));
    }
  }
  [class*="__hf-container"] {
    max-width: rem(1700);
    padding: 0 rem(10);
    margin: 0 auto;
  }
} @else {
  // По брейк-поинтам
  [class*="__container"] {
    margin: 0 auto;
    @if ($maxWidthContainer>0) {
      max-width: rem($maxWidthContainer);
      box-sizing: content-box;
    } @else {
      @if ($containerPadding>0) {
        padding: 0 rem(math.div($containerPadding, 2));
      }
    }
    @media (max-width: $pc) {
      max-width: rem(970);
    }
    @media (max-width: $tablet) {
      max-width: rem(750);
    }
    @media (max-width: $mobile) {
      max-width: none;
      @if ($containerPadding>0 and $maxWidthContainer>0) {
        padding: 0 rem(math.div($containerPadding, 2));
      }
    }
  }
}

// Подключение базовых стилей, шаблонов (заготовок) и вспомогательных  классов
// Для подключения/отключения конкретных стилей смотри base.scss
@import "src/scss/base";

// Подключение стилей общих элементов проекта
@import "src/scss/common";
@import "src/scss/filter";
@import "src/scss/basket";
@import "src/scss/preloader";
@import "src/scss/404";

// Подключение стилей отдельных блоков
@import "src/scss/header";
@import "src/scss/footer";

// Подключение стилей отдельных страниц
@import "src/scss/pages/home";
@import "src/scss/pages/contacts";
@import "src/scss/pages/about";
@import "src/scss/pages/brands";
@import "src/scss/pages/brand";
@import "src/scss/pages/categories";
@import "src/scss/pages/category";
@import "src/scss/pages/product";
@import "src/scss/pages/auth";
@import "src/scss/pages/checkout";
@import "src/scss/pages/account";
@import "src/scss/pages/news";
@import "src/scss/pages/basket";


@import "src/scss/base/swiper";

 #root{
   height: 100%;
 }
*{
  scroll-behavior: smooth;
}
//p{
//  text-align: justify;
//}
