.page {
  &__popular-categories {
    font-family: 'Lovelace';
    color: #000000;
    position: relative;
  }
}

.popular-categories {
  position: relative;
  &__bg {
    position: absolute;
    pointer-events: none;
    @media(max-width:$tablet){
      display: none;
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    &:first-child{
      right: 87%;
      top: rem(215);
      padding-bottom: rem(283);
      @include adaptiveValue("padding-bottom", 283, 100);
      width: rem(316);
    }
    &:nth-child(2){
      right: rem(-149);
      padding-bottom: rem(614);
      width: rem(682);
      mix-blend-mode: overlay;
    }
  }

  &__header {
  }

  &__title {
    @media(max-width:$tablet){

    }
  }

  &__content {
    padding: rem(100) 0;

    @media(max-width:$tablet){
      padding: rem(30) 0;
    }

    @media(min-width:$tablet){
      border-top: 1px solid #000000;
    }
    @media(max-width:$tablet){
      border-bottom: 1px solid rgba(148, 160, 115, 0.2);
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: rem(30);
    margin: 0 auto;
  }

  &__list {
    font-style: normal;
    font-weight: 300;
    @include adaptiveValue("font-size", 30, 16);
    line-height: math.div(42,30);
    display: flex;
    @include adaptiveValue("max-width", 1090, 100);
    @media(max-width:$tablet){
        max-width: rem(500);
    }
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    gap: rem(30);
    padding: rem(10);
    @include adaptiveValue("row-gap", 30, 10);
    @media(max-width:$tablet){
        flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }

  &__list-item {
    display: flex;
    align-items: center;
  }

  &__list-link {
    flex: 1 1 auto;
    transition: color 0.3s ease;
    padding: 0 rem(30);
    border: 1px solid #FFFFFF;
    border-radius: 1000px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @media(any-hover: hover){
      &:hover{
        text-decoration: none;
        background-color: #FFFFFF;
        border: 1px solid #FFFFFF;
        box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.3);
        color: #41492C;
      }
    }
    text-align: center;
    &.active{
      background-color: #FFFFFF;
      border: 1px solid #FFFFFF;
      box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.3);
      color: #41492C;
    }
  }
  &__show-all{
    @media(max-width:$tablet){
      position: absolute;
      top: rem(10);
      right: rem(10);
      p{
        display: none;
      }
      &:before{
          content: "все";

      }

      margin-top: 0;
      padding: rem(6.5) rem(15);
      border: 1px solid #DADADA;
      border-radius: 1000px;
      background: none;
    }
  }
}
