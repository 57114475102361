.page {
  &__category-products {
    margin-top: rem(30);
    @media(max-width:$tablet){
      padding: 0 rem(10);
    }
  }
}

.category-products {
  &__box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: rem(30);
    margin-bottom: rem(30);
    @media(max-width: em(1440)){
      grid-template-columns: 1fr 1fr;
    }
    @media(max-width:$tablet){
      gap: rem(10);
    }
  }

  &__item {
    border-color: #C7C7C7;
  }
}

.category-product {
  &__image {
  }

  &__content-box {
  }

  &__content {
  }

  &__title {
  }

  &__link {
  }

  &__subtitle {
  }

  &__info {
  }

  &__price {
  }

  &__current {
  }

  &__old {
  }

  &__rating {
  }

  &__value {
  }
}

