.page {
  &__account-info {
    flex: 0 0 rem(497);
    margin-left: rem(30);
    padding-bottom: rem(169);
    @media(max-width:$tablet){
      margin-left: rem(10);
      margin-right: rem(10);
      padding-bottom: rem(30);
      flex: 0 0 auto;
    }
    .account-orders-main &{
      flex: 1 1 auto;
    }
  }
}

.account-info {
  &__wrapper {
    margin-top: rem(30);
  }

  &__title {
    font-family: 'Lovelace';
    font-style: normal;
    font-weight: 400;
    font-size: rem(22);
    line-height: math.div(31,22);
    margin-bottom: rem(16);
    color: #313525;
  }
  &__subtitle{
    font-family: 'Gilroy';
    font-weight: 500;
    font-size: rem(18);
    line-height: math.div(22,18);
    color: #A3A3A3;
    max-width: rem(708);
  }
  &__form {
    padding: rem(10);
    background-color: rgba(148, 160, 115, 0.2);
    border-radius: 5px;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: rem(15);
    margin-bottom: rem(20);
  }

  &__image {
    flex: 0 0 rem(84);
  }
  &__image-label{
    position: relative;
    padding-bottom: rem(84);
    display: block;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: 2px solid #FFFFFF;
        border-radius: 50%;
    }
  }
  &__image-input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  &__name {
    font-weight: 500;
    font-size: rem(18);
    line-height: math.div(22,18);
    
    color: #41492C;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    row-gap: rem(20);
  }
  &__save{
    margin-top: rem(20);
    button{
      display: block;
      width: 100%;
    }
  }
  &__inputs-flex{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: rem(10);
    align-items: baseline;
    @media(max-width:$mobile){
      grid-template-columns: 1fr;
      margin-bottom: rem(20);
    }
  }
  &__inputs-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: rem(10);
    row-gap: rem(30);
    align-items: flex-end;
    @media(max-width:$mobile){
      grid-template-columns: 1fr;
    }
  }
}
