.page {
  &__product-info {
    @include adaptiveValue("margin-bottom", 106, 20);
  }
}

.product-info {
  &__container {
      flex: 1 1 auto;
  }

  &__items {
    display: flex;
    flex-direction: column;
  }

  &__item {
  }

  &__item-title {
    padding-bottom: rem(10);
    border-bottom: 1px solid #C7C7C7;
    margin-bottom: rem(20);

    font-family: 'Lovelace';
    font-weight: 400;
    @include adaptiveValue("font-size", 30, 14);
    line-height: math.div(42,30);
    color: #94A073;
  }

  &__item-text {
    font-family: 'Gilroy';
    font-weight: 400;
    font-size: 16px;
    @include adaptiveValue("font-size", 16, 14);
    line-height: math.div(25,16);
    color: #5C5C5C;
  }
}
