@use 'sass:math';

.page {
  &__about-content {
    margin-bottom: rem(80);
  }
}

.about-content {
  &__container {
  }

  &__box {
    display: flex;
    flex-direction: column;
    @media(max-width:$tablet){
        margin-bottom: rem(20);
        gap: rem(30);
    }
    gap: rem(25);
  }

  &__item {
    display: flex;
    @media(max-width:$tablet){
        flex-direction: column;
        &:first-child{
          flex-direction: column-reverse;
          gap: rem(30);
        }
    }
    gap: rem(32);
  }

  &__item-image {
    position: relative;
    flex: 0 0 50%;
    padding-bottom: 30%;
    @media(max-width:$tablet){
      padding-bottom: rem(250);
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
  }

  &__item-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #5C5C5C;
    text-align: justify;
    h2{
      font-family: 'Lovelace';
      font-style: normal;
      font-weight: 400;
      @include adaptiveValue("font-size", 30, 20);
      line-height: math.div(42,30);
      color: #41492C;
      padding-bottom: rem(10);
      border-bottom: 1px solid #C7C7C7;
      margin-bottom: rem(20);
    }
  }
}
