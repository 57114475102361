.account {
  &__orders {
    margin-top: rem(28);
  }

  &__orders-filter {
    display: flex;
    gap: rem(10);
    margin-bottom: rem(20);
  }

  &__orders-filter-item {
    padding: rem(10) rem(20);
    background-color: #FFFFFF;
    &.active{
      background-color: #E0E0DF;
    }
    border-radius: 100px;
  }

  &__orders-items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 350px));
    @media(max-width:$tablet){
      justify-content: center;
    }
    gap: rem(20);
  }

  &__orders-item {
    background-color: #FFFFFF;
    padding: rem(20) 0 rem(20) rem(20);
    border-radius: 20px;
    max-width: rem(350);
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
}

.account-order {
  &__title {
    font-weight: 700;
    font-size: rem(18);
    line-height: math.div(22,18);
    color: #313525;
    margin-bottom: rem(10);
  }

  &__status-date {
    display: flex;
    gap: rem(10);
    margin-bottom: rem(10);
  }

  &__status {
    font-weight: 600;
    font-size: rem(14);
    line-height: math.div(17,14);
    

    &.active{
      color: #36AA00;
    }
    &.preparing{
      color: #94A073;
    }
    &.canceled{
      color: #BC1F1F;
    }
    &.finished{
      color: #878787;
    }
  }

  &__date {
    font-weight: 500;
    font-size: rem(14);
    line-height: math.div(17,14);
    color: #878787;
  }

  &__payed {
    font-size: 14px;
    line-height: 17px;
    color: #313525;
    display: flex;
    gap: rem(10);
    span{
      font-weight: 600;
    }
    p{
      font-weight: 500;
    }
  }

  &__products {
    margin-bottom: rem(20);
  }

  &__more {
    display: block;
    font-weight: 600;
    font-size: rem(16);
    line-height: math.div(20,16);
    color: #FFFFFF;
    text-align: center;
    padding: rem(10) 0;
    background-color: #94A073;
    border-radius: 100px;
    margin-right: rem(20);
    margin-top: auto;
  }
}

.order-products {
  &__swiper-wrapper {
  }

  &__content-item {
    @media(any-hover: hover){
      &:hover{
        text-decoration: none;
      }
    }
  }
}

.order-product {

  &__image {
    width: rem(120);
    position: relative;
    padding-bottom: rem(120);
    background-color: #fff;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
  }

  &__name {
    font-weight: 600;
    font-size: rem(14);
    line-height: math.div(17,14);
    color: #313525;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: rem(7);
  }

  &__article {
    font-weight: 500;
    font-size: rem(12);
    line-height: math.div(15,12);
    color: #878787;
  }
}
