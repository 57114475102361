@font-face {
	font-family: "icons";
	font-display: swap;
	src: url("../../fonts/icons.ttf") format("truetype");
	font-style: normal;
}
%ic {
	font-family: "icons";
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
[class*="_icon-"] {
	&:before {
		@extend %ic;
		display: block;
	}
}
._icon-percent:before {
	content: "\e90a";
}
._icon-bag:before {
	content: "\e90b";
}
._icon-arrow-long:before {
	content: "\e908";
}
._icon-arrow-slider:before {
	content: "\e909";
}
._icon-facebook:before {
	content: "\e903";
}
._icon-instagram:before {
	content: "\e904";
}
._icon-tel:before {
	content: "\e905";
}
._icon-telegram:before {
	content: "\e906";
}
._icon-whatsapp:before {
	content: "\e907";
}
._icon-basket:before {
	content: "\e901";
}
._icon-account:before {
	content: "\e900";
}
._icon-search:before {
	content: "\e902";
}
