@font-face {
  font-family: Gilroy;
  font-display: swap;
  src: url("../../fonts/Gilroy/Gilroy-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Gilroy;
  font-display: swap;
  src: url("../../fonts/Gilroy/Gilroy-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Gilroy;
  font-display: swap;
  src: url("../../fonts/Gilroy/Gilroy-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Lovelace;
  font-display: swap;
  src: url("../../fonts/Lovelace/Lovelace-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Lovelace;
  font-display: swap;
  src: url("../../fonts/Lovelace/Lovelace-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Lovelace;
  font-display: swap;
  src: url("../../fonts/Lovelace/Lovelace-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Lovelace;
  font-display: swap;
  src: url("../../fonts/Lovelace/Lovelace-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: TheYoungest;
  font-display: swap;
  src: url("../../fonts/TheYoungest/TYScript-Rg.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
