.page {
  &__product-reviews {
    margin-bottom: rem(86);
  }
}

.product-reviews {
  &__count {
    font-family: 'Lovelace';
    font-style: normal;
    font-weight: 400;
    font-size: rem(16);
    line-height: math.div(31,16);
    
    text-align: center;
    color: #FFFFFF;
    padding: rem(25) 0;
    background-color: #94A073;
    margin-bottom: rem(30);
  }

  &__container {
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(30);
  }
  &__add{
    padding: rem(12) rem(66);
    background-color: #E55930;
    border: 1px solid #C7C7C7;
    font-weight: 500;
    font-size: rem(16);
    line-height: math.div(19,16);
    align-self: flex-start;
    color: #FFFFFF;
    @media(max-width:$tablet){
      padding: rem(13) rem(19.5);
    }
  }
  &__filter {
    display: flex;
    align-items: center;
    gap: rem(20);
    p{
      align-self: flex-start;
      padding-top: rem(18);
    }
    @media(max-width:$tablet){

        p{
          display: none;
        }
    }
    .select{
      @include adaptiveValue("min-width", 200, 100);
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap:rem(40);
  }

  &__item {
  }
}

.product-review {
  &__info {
    display: flex;
    align-items: center;
    gap: rem(10);
    margin-bottom: rem(20);
  }

  &__info-avatar {
    flex: 0 0 rem(60);
    padding-bottom: rem(60);
    background-color: #D9D9D9;
    border-radius: 50%;
    position: relative;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
  }

  &__info-content {
  }

  &__info-name {
    font-family: 'Lovelace';
    font-style: normal;
    font-weight: 400;
    @include adaptiveValue("font-size", 22, 16);
    line-height: math.div(31,22);
    color: #000000;
  }

  &__info-rating-box {
    display: flex;
    gap: rem(10);
  }
  &__images{
    display: flex;
    gap: rem(10);
    margin-bottom: rem(20);
  }
  &__image{
    position: relative;
    flex: 0 0 rem(100);
    padding-bottom: rem(100);
    border: 1px solid #94A073;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 70%;
        height: 70%;
        object-fit: contain;
    }
  }
  &__info-date {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 300;
    @include adaptiveValue("font-size", 16, 14);
    line-height: math.div(19,16);
    
    color: #5C5C5C;
  }

  &__content {
    padding-bottom: rem(20);
    border-bottom: 1px solid #C7C7C7;
    &.reply{
      margin-left: 40px;
      h3{
        font-weight: 700;
        font-size: rem(16);
        margin-bottom: rem(10);
      }
    }
    &:not(:last-child){
      margin-bottom: rem(30);
    }
  }
}
