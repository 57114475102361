.logo{
  flex: 0 0 percent(171, 1720);
}
.logo-link{
  position: relative;
  padding-bottom: rem(76);
  @media(max-width:$tablet){
    padding-bottom: rem(40);
  }
  display: block;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.section-title{
  font-weight: 300;
  @include adaptiveValue("font-size", 60, 20);
  line-height: math.div(81,60);
  
  text-align: center;
  @include adaptiveValue("padding-bottom", 33, 10);
  @include adaptiveValue("padding-top", 33, 10);
  border-bottom: 1px solid #000000;
  border-top: 1px solid #000000;
  @media(max-width:$tablet){
    text-align: left;
    padding-left: rem(10);
    border-bottom: 1px solid #DADADA;
    border-top: 1px solid #DADADA;
  }
}
.products{
  display: flex;
  @media(max-width:$tablet){
    display: inherit;
  }
}
.product {
  &.no-avl{
    img {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
    }
  }
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 2px 8px rgba(148, 160, 115, 0.15));
  border-radius: 5px;
  overflow: hidden;
  background-color: #FFFFFF;
  position: relative;
  &-image {
    position: relative;
    padding-bottom: rem(400);
    max-height: rem(400);
    background-color: #fff;
    @media(min-width:$tablet){
        flex: 1 1 auto;

    }
    @media(max-width:$tablet){
      padding-bottom: rem(180);
      max-height: rem(180);
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
  }

  &-content {
  }
  &-content-box{
    @include adaptiveValue("padding", 20, 10);
    display: flex;
    flex-direction: column;
    height: 100%;
    p{
      text-align: left;
    }
  }
  &-title{
    font-family: 'Lovelace';
    font-weight: 400;
    @include adaptiveValue("font-size", 22, 14);
    line-height: math.div(31,22);
    color: #000000;
    margin-bottom: rem(10);

  }
  &-link {

  }

  &-subtitle {
    font-family: 'Gilroy';
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #727171;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: rem(10);
    @media(max-width:$tablet){
      display: none;
    }
  }
  &-info{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
    font-family: 'Lovelace';
    @media(max-width:$tablet){
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: flex-start;
      gap: rem(10);
    }
  }
  &-price {
    &__current{
      font-weight: 400;
      @include adaptiveValue("font-size", 22, 16);
      line-height: math.div(31,22);
      color: #41492C;
    }
    &__old{
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-decoration: line-through;
      color: #A0A0A0;
    }
  }
  &-sale{
    position: absolute;
    display: block;
    padding: rem(10) rem(24.5);
    background-color: #E55930;
    border-radius: 5px;
    font-weight: 500;
    font-size: rem(16);
    @include adaptiveValue("font-size", 16, 12);
    line-height: math.div(19,16);
    color: #FFFFFF;
    @media(max-width:$tablet){
      padding: rem(5) rem(12);
    }
    z-index: 10;
    top: rem(10);
    left: rem(10);
  }
  &-rating {
    font-size: rem(16);
    display: flex;
    flex-direction: column;
    gap: rem(5);
    @media(max-width:$tablet){
        flex-direction: row;
        gap: rem(12);
    }
    &__value{
      font-weight: 400;
      @include adaptiveValue("font-size", 16, 12);
      line-height: math.div(14,12);
      text-decoration: underline;
      @media(any-hover: hover){
        &:hover{
          cursor: pointer;
        }
      }
      color: #5C5C5C;
    }
  }
  &__link{
    font-family: Lovelace;
    display: block;
    width: 100%;
    text-align: center;
    padding: rem(14) 0;
    font-weight: 500;
    @include adaptiveValue("font-size", 18, 12);
    line-height: math.div(22,18);
    background-color: rgba(148, 160, 115, 0.2);
    border-radius: 5px;
    color: #41492C;
    &:not(:last-child){
      margin-bottom: rem(5);
    }
    @media(any-hover: hover){
      &:hover{
            text-decoration: underline;
      }
    }
  }
}
.rating-item, .rating-item-fill{
  color: #CBCBCB;
  &:before{
      content: "★";
  }
}
.rating-item-fill {
  color: #DEB00F;
}
.show-btn{
  display: block;
  font-family: 'Gilroy';
  font-weight: 500;
  font-size: rem(18);
  line-height: math.div(22,18);
  padding: rem(14) 0;
  color: #41492C;
  background: rgba(148, 160, 115, 0.2);
  border-radius: 10px;
  max-width: rem(290);
  margin: 0 auto;
  text-align: center !important;
  p{
    text-align: center !important;
  }
  //@media(max-width:$tablet){
  //  position: absolute;
  //  top: 0;
  //}
}
.sugar {
  &__items {
    display: flex;
    gap: rem(20);
    margin-top: rem(20);
    font-weight: 400;
    font-size: rem(16);
    line-height: math.div(19,16);
    span, a{
      color: #A1A1A1;
    }
  }

  &__item {
    color: #41492C;
    &:last-child{
      max-width: 40%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  &__container{
      @media(max-width:$tablet){
        display: none;
      }
  }
  &__mobile{
    padding: rem(10);
    font-family: 'Gilroy';
    border-bottom: 1px solid #DADADA;
    &-back{
      @media(any-hover: hover){
        &:hover{
              text-decoration: none;
        }
      }
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #5C5C5C;
      &:before{
        font-size: rem(24);
      }
    }
    @media(min-width:$tablet){
      display: none;
    }
  }
}
.container-1{
  max-width: rem(1402);
  padding: 0 rem(10);
  margin: 0 auto;
}
.page-title{
  font-family: 'Lovelace';
  font-weight: 400;
  font-size: rem(30);
  line-height: math.div(42,30);
  
  color: #363636;
}
.page-subtitle{
  font-weight: 500;
  font-size: rem(16);
  line-height: math.div(19,16);
  color: #A3A3A3;
  text-indent: rem(15);
  text-align: justify;
  p{
    &:not(:last-child){
      margin-bottom: rem(10);
    }
  }
}
.contacts-main{
  display: flex;
  flex-direction: column;
}
.account-main{
  &__wrapper{
    display: flex;
    min-height: 100%;
    @media(max-width:$tablet){
      flex-direction: column;
    }
  }
}
.mt-20{
  margin-top: rem(20);
}
error{
  position: absolute;
  right: rem(0);
  color:  #9a2c2c;
}
