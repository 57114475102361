//<INPUT>
// Снипет (HTML): inp
input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}
.input {
	&._focus {
	}
	&._error {
	}
	&__box{
		position: relative;
		display: flex;
		flex-direction: column;
		gap: rem(5);
		height: fit-content;
	}
	&__label {
		position: absolute;
		transform: translateY(-50%);
		left: 10px;
		top: 50%;
		font-weight: 500;
		font-size: rem(16);
		line-height: math.div(19,16);
		color: #41492C;
		transition: top 0.3s ease, left 0.3s ease;

	}
	&__select{
		min-width: 100% !important;
		.select__head{
			border: none;
			&:after{
				display: none;
			}
		}
		.input__box{
			margin: 0;
		}
		.select__list{
			top: 70px;
		}
	}
	&__item {
		padding: rem(15.5) rem(10);
		background-color: #FFFFFF;
		border-radius: 5px;
		font-weight: 500;
		font-size: rem(16);
		line-height: math.div(19,16);
		color: #7C7C7C;
		transition: margin-top 0.3s ease;
		.full &{
			margin-top: rem(20);
			&+label{
				top: 5px;
				left: 0px;
			}
		}
		&:focus{
			margin-top: rem(20);
			&+label{
				top: 5px;
				left: 0px;
			}
		}
	}
}
