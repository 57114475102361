.page {
  &__first-screen {
  }
}

.first-screen {
  &__box{
    display: flex;
    width: 100%;
    max-width: 2440px;
    margin: 0 auto;
    @media(max-width:$tablet){
      display: none;
    }
  }
  &__products-box {
    //background-color: #94A073;
    flex: 0 0 percent(250,2440);
    z-index: 0;
  }
  &__products-image{
    position: relative;
    padding-bottom: percent(550,182);
    img {
        position: absolute;
        $size: 100%;
        top:  0;
        left: 0;
        width: $size;
        height: $size;
        object-fit: cover;
    }
  }

  &__products {
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    &.orange-box {
      background-color: #E6D8C2;
    }

    &.grey-box {
      background-color: #DDDFDB;
    }

    position: relative;

    &:before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      background: url("../../../img/Home/FirstScreen/prodbg.png") center/cover no-repeat;
      mix-blend-mode: overlay;
    }
  }
  &__product {
    position: relative;
    padding-bottom: 100%;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
  }
  &__content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    position: relative;
    z-index: 1;
    overflow: hidden;
  }
  &__bg{
    position: absolute;
    padding-bottom: 100%;
    width: 100%;
    mix-blend-mode: overlay;
    top: -150px;
    img {
        transition: object-position 0.4s linear;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center 0;
    }
  }
  &__bg-l{
    position: absolute;
    mix-blend-mode: overlay;

    img {
      transition: object-position 0.4s linear;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center 0;
    }
    &:nth-child(1){
      padding-bottom: rem(263);
      width: rem(317);
      right: -5%;
      bottom: 0;
      img{
        object-position: right;
      }
    }
    &:nth-child(2){
      padding-bottom: rem(263);
      width: rem(212);
      left: 25%;
      img{
        object-position: right;
      }
    }
    &:nth-child(3){
      padding-bottom: rem(281);
      width: rem(523);
      left: -8%;
      top: 0;
      img{
        object-position: left;
      }
    }
    &:nth-child(4){
      padding-bottom: rem(400);
      width: rem(515);
      right: 2%;
      top: -18%;
      img{
        object-position: right;
      }
    }

  }
  &__title {
    font-family: 'Lovelace';
    font-style: normal;
    font-weight: 300;
    @include adaptiveValue("font-size", 130, 50);
    line-height: 90px;
    color: #41492C;
    z-index: 1;

  }
  &__subtitle {
    font-family: 'TheYoungest';
    font-weight: 400;
    @include adaptiveValue("font-size", 40, 20);
    line-height: 75px;
    color: #94A073;
    z-index: 1;

  }
  // ----------mobile------------ //
  &__mobile-box {
    margin-top: rem(20);
    @media(min-width:$tablet){
      display: none;
    }
  }

  &__mobile-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 rem(10);
    text-align: center;
    position: relative;
    .first-screen__bg-l-items{
      .first-screen__bg-l{
        position: absolute;
        mix-blend-mode: normal;
        transition: transform 0.4s linear;

        &:nth-child(1){
          padding-bottom: rem(263);
          width: rem(80);
          right: -25px;
          bottom: 20px;
          animation: anim-shup 5s linear infinite;
          img{
            object-position: right;
          }
        }
        &:nth-child(2){
          padding-bottom: rem(263);
          width: rem(100);
          left: -30px;
          top: 20%;
          animation: anim-shup 5s linear infinite;
          transform-origin: center left;
          img{
            object-position: right;
          }
        }
        &:nth-child(3){
          padding-bottom: rem(100);
          width: rem(100);
          left: -8%;
          top: 30px;
          animation: anim-shup 5s linear infinite;
          img{
            object-position: left;
          }
        }
        &:nth-child(4){
          padding-bottom: rem(400);
          width: rem(100);
          right: -20px;
          top: -18%;
          animation: anim-shup 5s linear infinite;
          img{
            object-position: right;
          }
        }
      }
    }

  }

  &__mobile-title {
    font-family: 'Lovelace';
    font-weight: 300;
    font-size: rem(30);
    line-height: math.div(30,30);
    
    color: #000000;
    margin-bottom: rem(10);
  }

  &__mobile-subtitle {
    font-family: 'TheYoungest';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 57px;
    background: linear-gradient(99.61deg, #94A073 -6.58%, rgba(96, 116, 41, 0.49) 55.33%, rgba(148, 160, 115, 0.08) 118.55%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-align: center !important;
  }

  &__swiper {
    position: relative;
  }

  &__swiper-pagination {
    position: absolute;
    bottom: rem(5);
    left:50%;
    transform: translateX(-50%);
    display: flex;
    gap: rem(10);

    .swiper-pagination-bullet{
      width: rem(10);
      height: rem(10);
      border: 1px solid #fff;
      border-radius: 50%;
      background-color: #94A073;
      transition: background-color 0.3s ease;
    }
    .swiper-pagination-bullet-active{
      background-color: #E55930;
    }
  }
  &__swiper-wrapper {
  }

  &__swiper-slide {
    position: relative;
    padding-bottom: 100%;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

  }
  
}
@keyframes anim-shup {
  0%{
    transform: rotate(0deg);
  }
  50%{
    transform: rotate(9deg);
  }
  100%{
    transform: rotate(0deg);
  }
}

