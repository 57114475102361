.page {
  &__brands {
    max-width: 100%;
    padding-bottom: rem(48);
    @include adaptiveValue("padding-bottom", 48, 20);
    position: relative;
  }
}

.brands {
  &__header {

    margin-bottom: rem(20);
    @include adaptiveValue("margin-bottom", 20, 0);
  }
  &__show-all{
    @media(max-width:$tablet) {
      position: absolute;
      top: rem(10);
      right: rem(10);

      p {
        display: none;
      }
      &:before {
        content: "все";

      }
      padding: rem(6.5) rem(15);
      border: 1px solid #DADADA;
      border-radius: 1000px;
      background: none;
      font-weight: 300;
      font-size: 14px;
      line-height: 17px;
      color: #585858;
    }
  }
  &__title {
    font-family: 'Lovelace';
    color: #000000;

    @media(max-width:$tablet){
      padding-bottom: rem(10) !important;
      border-top: none;
      border-bottom: 1px solid #DADADA !important;
    }
  }
  &__slider{
    padding: 0 rem(48);
    @media(max-width:$tablet){
      padding: 0;
    }
  }
  &__content {
    margin-bottom: rem(20);
  }

  &__swiper-wrapper {

  }

  &__content-item {
    @media(min-width:$tablet){
        p{
          display: none;
        }
    }
  }
  &__buttons {
    @media(max-width:$tablet){
      display: none;
    }
    button{
      top: 50%;
      z-index: 5;
      position: absolute;
      background-color: #94A073;
      @include adaptiveValue("width", 60, 40);
      @include adaptiveValue("height", 60, 40);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      &:before{
          font-size: 32px;
          color: #fff;
        @include adaptiveValue("font-size", 32, 24);
      }
    }
    .brands-prev-btn {
      transform:translateX(-30%) translateY(-50%);
      left: 0;
      @media(max-width:$tablet){
        transform:translateX(-120%) translateY(-50%);
      }
    }
    .brands-next-btn {
      right: 0;
      transform:translateX(30%) rotate(180deg) translateY(50%);
      @media(max-width:$tablet){
        transform:translateX(120%) rotate(180deg) translateY(50%);
      }
    }
  }
  &-swiper{
    position: relative;
    @include adaptiveValue("margin-top", 40, 20);
    &:before{
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        transform: translateX(-100%);
        background-color: #F1F0ED;
        z-index: 2;
    }
    &:after{
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 100%;
      transform: translateX(100%);
      background-color: #F1F0ED;
      z-index: 2;
    }
  }
}

.brands-item {


  &__image {
    position: relative;
    @include adaptiveValue("padding-bottom", 160, 100);
    display: block;
    overflow: hidden;
    border-radius: 5px;
    @media(max-width:$tablet){
      &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
        z-index: 1;
      }
    }
    &.categories-item{
      p{
        display: block;
      }
      &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
        z-index: 1;
      }
      img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
      }
    }
    p{
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @media(max-width:$tablet){
        transform: translate(0%, 0%);
        top: initial;
        bottom: rem(10);
        left: rem(10);
      }
      font-family: 'Gilroy';
      font-weight: 500;
      @include adaptiveValue("font-size", 30, 16);
      line-height: math.div(36,30);
      color: #FFFFFF;
      text-align: left;
    }
    &:not(.categories-item){
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        height: 80%;
        object-fit: contain;
      }
    }

  }
}


