.page {
    &__categories-banners {
        margin-top: rem(24);
    }
}

.categories-banners {
    &__container {
    }
    &__items {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @media(max-width:$tablet){
            grid-template-columns: 1fr;
        }
        gap: rem(20);
    }
    &__item{
        background-color: #F4F4F4;
        @media(max-width:$tablet){
            background: #FFFFFF;
            border: 1px solid #CDCCCB;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            position: relative;
            overflow: hidden;
        }
    }

}

.category-banner {
    display: flex;
    @media(max-width:$tablet){
        flex-direction: column;
    }
    &__info {
        flex: 1 1 auto;
        padding: rem(30);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    }

    &__title {
        font-weight: 500;
        font-size: rem(30);
        @include adaptiveValue("font-size", 30, 16);
        line-height: math.div(36,30);
        text-transform: uppercase;
        color: #000000;
    }

    &__text {
        font-weight: 400;
        @include adaptiveValue("font-size", 16, 12);
        line-height: math.div(19,16);
        color: #898989;
    }

    &__show-more {
        margin: 0;
        &:before{
            @extend %ic;
            @extend ._icon-arrow-slider;
            font-size: rem(32);
            color: #FFFFFF;
            @media(min-width:$tablet){
                display: none;
            }
        }
        @media(max-width:$tablet){
            position: absolute;
            bottom: rem(15);
            right: rem(15);
            z-index: 10;
            background: rgba(0, 0, 0, 0.4);
            p{
                display: none;
            }
            width: rem(60);
            height: rem(60);
            border-radius: 50%;
            transform: rotate(180deg);
            @media(any-hover: hover){
              &:hover{
                    text-decoration: none;
              }
            }
        }
    }

    &__image {
        position: relative;
        flex: 0 0 rem(354);
        padding-bottom: percent(344, 854);
        @media(max-width:$tablet){
            flex: 0 0 rem(200);
        }
        @media(min-width:$tablet){
            border-radius: 172px 0px 0px 172px;
        }

        overflow: hidden;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}



