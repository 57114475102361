.page {
  &__account-info {
  }
}

.account-info {
  &__wrapper {
  }

  &__title {
  }

  &__subtitle {
    @include adaptiveValue("margin-bottom", 70, 20);
  }
}

.account-order {
  &__table {
    border-collapse: separate;
    border-spacing: 0 5px;
    width: 100%;
    text-align: left;
    max-width: 1013px;
    @media(max-width:$tablet){
      display: none;
    }
    tbody > tr > td:first-child > div {
      position: relative;
      padding-bottom: rem(86);
      width: rem(86);
      background-color: #fff;
      border-radius: 5px;
      overflow: hidden;
      img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;
      }
    }

    thead{
      font-weight: 500;
      font-size: rem(14);
      line-height: math.div(17,14);
      color: #A3A3A3;
      padding-bottom: rem(9);
      border-spacing: 10px;
    }

    th {
      border-bottom: 1px solid #E1E1E1;
      border-collapse: separate;
      padding-bottom: rem(10);
    }

    td {
      padding: 20px 0;
      max-width: rem(50);
    }
    td:not(:first-child){
      padding-left: rem(10);
    }
    th:not(:first-child){
      padding-left: 10px;
    }
    tbody{
      tr:not(:last-child) td{
        border-bottom: 1px solid #E1E1E1;
        border-collapse: separate;
      }
    }
  }
}
.account-order {
  &__mobile {
    @media(min-width:$tablet){
      display: none;
    }
  }

  &__items {
    display: grid;
    
    gap: rem(10);
  }

  &__item {
    display: flex;
    align-items: center;
    gap: rem(5);
  }
}

.account-order-item {
  &__image {
    position: relative;
    padding-bottom: rem(192);
    background-color: #fff;
    flex: 0 0 rem(184);
    @media(max-width:$mobile){
        flex: 0 0 rem(100);
    }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: rem(10);
  }

  &__article {
    font-weight: 400;
    font-size: rem(14);
    line-height: math.div(17,14);
    
    color: #AEAEAE;
  }

  &__name {
    font-weight: 400;
    font-size: rem(16);
    line-height: math.div(22,16);
    
    color: #000000;
  }

  &__price {
    font-family: 'Lovelace';
    font-weight: 400;
    font-size: rem(22);
    line-height: math.div(31,22);
    color: #94A073;
  }

  &__value {
    font-weight: 600;
    font-size: rem(14);
    line-height: math.div(18,14);
    
    color: #41492C;
  }
}
