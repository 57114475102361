.basket {
  font-family: 'Lovelace';
  font-weight: 400;
  background: #F1F0ED;
  border: 1px solid #94A073;
  &__header {
    display: flex;
    align-items: center;
    gap: rem(15);
    padding-bottom: rem(15);
    border-bottom: 1px solid #94A073;
  }

  &__logo {
  }

  &__title {
    font-size: rem(20);
    line-height: math.div(25,20);
    letter-spacing: 0.03em;
    color: #363B48;
  }
   &__close{
     width: rem(25);
     height: rem(25);
     margin-left: auto;
     @media(max-width:$tablet){
       top: rem(15);
       right: rem(25);
       width: rem(30);
       height: rem(30);
     }
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     z-index: 1;
     border: 1px solid #8C9677;
     border-radius: 50%;
     span{
       display: block;
       width: rem(18);
       flex: 0 0 rem(2);
       border-radius: rem(1);
       background-color: #8C9677;
       transform-origin:  center;
     }
     span:first-child{
       transform: rotate(45deg);
     }
     span:last-child{
       transform: rotate(-45deg);
       margin-top: -2px;
     }
   }
  &__items {
    padding-bottom: rem(15);
    border-bottom: 1px solid #94A073;
    overflow-x: hidden;
    max-width: 600px;
    overflow-y: auto;
    max-height: 100%;
    &:not(:last-child){
      margin-bottom: 10px;
    }
    .search-results &{
      overflow: auto;
      max-height: 60vh;
    }
  }

  &__item {
    display: flex;
    padding-top: rem(20);
  }
  &__sum{
    font-size: rem(22);
    line-height: math.div(30,22);
    letter-spacing: 0.02em;
    color: #363B48;
    padding: rem(15) 0;
    margin-top: auto;
    span{
      font-size: rem(16);
    }
  }
  &__checkout{
    display: block;
    text-align: center;
    background: #94A073;
    border-radius: 5px;

    padding: rem(5) 0;
    font-size: rem(16);
    line-height: math.div(30,16);
    letter-spacing: 0.02em;
    color: #FFFFFF;
  }
  &__empty{
    font-size: rem(16);
    @include adaptiveValue("font-size", 16, 14);
    line-height: math.div(30,16);
    letter-spacing: 0.02em;
    color: #363B48;
    word-wrap: normal;
    padding-top: rem(15);
  }
  &__min{
    @include adaptiveValue("font-size", 14, 13);
    line-height: math.div(14,12);
    letter-spacing: 0.02em;
    color: #94A073;
    word-wrap: normal;
    margin-bottom: rem(10);
  }
}
.basket-window{
  position: fixed;
  height: 100%;
  padding: rem(15);
  background: #F1F0ED;
  border: 1px solid #94A073;
  top: 0;
  right: 0;
  z-index: 20003;
  &__wrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &__items{

  }
  &.search-results{
    position: absolute;
    height: auto;
    top: 100%;
    max-width: rem(450);
    right: initial;
    left: -85%;
    @media(max-width:$tablet){
      left: initial;
      right: 0;
      margin: 0 rem(10);
      max-width: none;
    }
  }
  @include adaptiveValue("min-width", 450, 320);
}
.basket-item {
  &__image{
    position: relative;
    padding-bottom: rem(150);
    flex: 0 0 rem(150);
    margin-right: rem(10);
    background-color: #fff;
    height: 0;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
  }
  &__text {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    row-gap: rem(10);
  }

  &__id {
  }

  &__title {
    @include adaptiveValue("font-size", 16, 14);
    line-height: math.div(30,16);
    letter-spacing: 0.02em;
    color: #363B48;
    word-wrap: normal;
  }
  &__subtitle{
    @include adaptiveValue("font-size", 10, 8);
    line-height: math.div(15,10);
    &.error{
      color: #E55930;
    }
  }
  &__remove {
    display: flex;
    svg{
      pointer-events: none;
    }
  }
    &__counter {
      display: flex;
      align-items: center;
      column-gap: rem(20);
      padding: rem(5);
      background-color:rgba(148, 160, 115, 0.2);
      max-width: fit-content;
      border-radius: rem(10);
      color: #41492C;
      margin-top: rem(5);
    }

    &__count-button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0,0,0,0);
    }

    &__count-value {
    }
}
.basket__close-wrapper{
  position: fixed;
  width: 100vw;
  height: 100vh;
  @media(max-width:$tablet){
    z-index: 20002;
  }
  z-index: 10;
  cursor: pointer;
  top: 0;
  left: 0;
  background-color: rgba(56, 53, 53, 0.4);
}
