.page {
  &__404 {
    text-align: center;
    margin-top: rem(150);
    @include adaptiveValue("margin-bottom", 100, 50);
  }
}

.four-oh-four{
  &__image {
    position: relative;
    padding-bottom: rem(229);
    max-width: rem(652);
    margin: rem(50) auto;
    @media(max-width:$tablet){
      margin: rem(0) auto;
    }
    width: calc(100% - 32px);
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
  }
  &__button{
    padding: rem(9) rem(20);
    background-color: #94A073;
    color: #FFFFFF;
    border-radius: 12px;
  }
  &__title {
    font-weight: 600;
    font-size: rem(35);
    line-height: math.div(43,35);
    color: #404A23;
    max-width: rem(652);
    margin: 0 auto rem(25);
  }
}
