.account {
  &__bonuses {
    padding: rem(20);
    background-color: rgba(148, 160, 115, 0.2);
    border: 1px solid #94A073;
    border-radius: 5px;
  }

  &__bonuses-title {
    font-weight: 500;
    @include adaptiveValue("font-size", 16, 12);
    line-height: math.div(19,16);
    color: #41492C;
    margin-bottom: rem(5);
  }

  &__bonuses-value {
    font-weight: 600;
    @include adaptiveValue("font-size", 40, 30);
    line-height:math.div(49,40);
    text-align: center;
    padding: rem(25.5) 0;
    @media(max-width:$tablet){
      padding: rem(21.5) 0;
    }
    background-color: #94A073;
    border-radius: 5px;
    color: #FFFFFF;
    margin-bottom: rem(20);
  }

  &__bonuses-purchases {
    border-top: 1px solid #94A073;
    padding-top: rem(20);

    font-weight: 500;
    @include adaptiveValue("font-size", 16, 12);
    line-height: math.div(19,16);
    
    color: #41492C;
  }
}
